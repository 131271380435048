export const locationOptions = [
  "Noida",
  "Gurgoan",
  "Delhi Ncr",
  "Mumbai",
  "Bangalore",
  "other",
];

export const levelOptions = [
  "helper",
  "Commie 3",
  "Commie 2",
  "Commie 1",
  "CDP",
  "DCDP",
  "Head",
  "AllRounder",
];

export const cuisineOptions = [
  "Indian",
  "Chinese",
  "Continental",
  "Tandoor",
  "South Indian",
  "other",
];

export const statusCode = {
  addchef: "ERR CH201",
};

export const listItems = {
  name: "Name",
  cuisine: "Cuisine",
  experience: "Experience",
  level: "Level",
  location: "Location",
  workLocation: "Open Work Location",
};

export const filters = ["level", "location", "cuisine"];

export const ChefListsHeader = [
    {
        id: 'name',
        // numeric: false,
        // disablePadding: true,
        label: 'Name',
      },
      {
        id: 'phone',
        numeric: true,
        label: 'Phone',
      },
      {
        id: 'experience',
        numeric: true,
        label: 'Experience',
      },
      {
        id: 'level',
        label: 'Level',
      },
      {
        id: 'cuisine',
        label: 'Cuisine',
        type: 'array'
      },
      {
        id: 'cur_location',
        label: 'Current Location',
      },
      {
        id: 'cur_salary',
        numeric: true,
        label: 'Current Salary',
        format: (value) => value.toFixed(2),
      }
]

export const ChefListHeaderCollapse = [
    {
        id: 'pre_location',
        label: 'Prefered Location',
        type: 'array'
      },
      {
        id: 'exp_salary',
        numeric: true,
        label: 'Expected Salary',
      },
      {
        id: 'opentoparttime',
        label: 'Open to part time',
      },
      {
        id: 'added_by',
        label: 'Added By',
      },
      {
        id: 'status',
        label: 'Status',
      },
      {
        id: 'actions',
        label: 'Actions'
      }
]

export const ChefFormDetails = (levelLocationSelect, handleSelect, data) => {
    let cuisineArr=[];
    let prefredLocationArr=[];
    if(data.length){
        cuisineArr = [...data[0].cuisine];
        prefredLocationArr = [...data[0].workLocation]
        if(levelLocationSelect.cuisine.length ){
            cuisineArr = levelLocationSelect.cuisine;
        }
        if(levelLocationSelect.workLocation.length){
            prefredLocationArr = levelLocationSelect.workLocation
        }
    }
    return [
      {
        type: "text",
        label: "Name",
        name: "name",
        defaultValue: data.length ? data[0].name : null,
        placeholder: "Enter Name",
        // changeHandler: handleInput,
      },
      {
        type: "text",
        label: "Contact",
        name: "phone",
        defaultValue: data.length ? data[0].phone : null,
        // value: chefDetails.phone,
        placeholder: "Enter Number",
        // changeHandler: handleInput,
      },
      {
        type: "text",
        label: "Current Location",
        name: "location",
        defaultValue: data.length ? data[0].location : null,
        // value: chefDetails.location,
        placeholder: "Enter Current Location",
        // changeHandler: handleInput,
      },
      {
        type: "select",
        label: "Type Of Chef",
        name: "cuisine",
        options: cuisineOptions,
        value: data.length ? cuisineArr : levelLocationSelect.cuisine,
        placeholder: "Select Cuisine",
        changeHandler: handleSelect,
      },
      {
        type: "select",
        label: "Level",
        name: "level",
        value: levelLocationSelect.level || (data.length && data[0].level),
        placeholder: "Select Level",
        changeHandler: handleSelect,
        options: levelOptions,
      },
      {
        type: "select",
        label: "Work Location",
        name: "workLocation",
        value: data.length ? prefredLocationArr : levelLocationSelect.workLocation ,
        placeholder: "Select Location",
        changeHandler: handleSelect,
        options: locationOptions,
      },
      {
        type: "text",
        label: "Experience",
        name: "experience",
        defaultValue: data.length ? data[0].experience : null,
        // value: chefDetails.experience,
        placeholder: "Experience in years",
        // changeHandler: handleInput,
      },
      {
        type: "text",
        label: "Current Salary",
        name: "curSalary",
        defaultValue: data.length ? data[0].curSalary : null,
        // value: chefDetails.curSalary,
        placeholder: "Current Salary/Last Salary",
        // changeHandler: handleInput,
      },
      {
        type: "text",
        label: "Expected Salary",
        name: "expSalary",
        defaultValue: data.length ? data[0].expSalary : null,
        // value: chefDetails.expSalary,
        placeholder: "Expected Salary",
        // changeHandler: handleInput,
      },
      {
        type: "checkbox",
        label: "Open to Part Time",
        name: "openToPartTime",
        // defaultValue: data.length && data[0].openToPartTime,
        value: levelLocationSelect.openToPartTime,
        changeHandler: handleSelect,
      },
    ];
}
  
